<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>User Audit</h5>
          </div>

          <div class="ibox-content">
            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="onSubmit" autocomplete="off">
                <div class="row" style="margin-left: 0.2%">
                  <div class="form-group col-md-2">
                    <label>Customer Name</label>
                    <select class="form-control" v-model="apiAuditModel.customerName" id="customer">
                      <option value>---- Select ----</option>
                      <option
                        v-for="(value, key) in customerData"
                        :key="key"
                        :value="value.id"
                      >{{ value.text }}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>User Id</label>
                    <input
                      type="text"
                      placeholder="User Id"
                      class="form-control"
                      v-model="apiAuditModel.userId"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Result Code</label>
                    <select class="form-control" v-model="apiAuditModel.resultCode" id="statusCode">
                      <option value>---- Select ----</option>
                      <option
                        v-for="(code, index) in statusCode"
                        v-bind:key="index"
                        :value="code"
                      >{{ code }}</option>
                    </select>
                  </div>

                  <div class="form-group col-md-2">
                    <label>
                      Date *
                      <i
                        class="fa fa-info-circle"
                        aria-hidden="true"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Only 7 days date selection allowed"
                      ></i>
                    </label>
                    <div class="input-group date">
                      <ValidationProvider
                        name="Date"
                        :rules="{ required: true }"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          class="form-control"
                          type="text"
                          :name="datePicker"
                          :id="datePicker"
                          autocomplete="off"
                          :class="classes"
                          v-model="apiAuditModel.callTime"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="form-group col-md-2">
                    <label>Server Details</label>
                    <input
                      type="text"
                      placeholder="Server Details"
                      class="form-control"
                      v-model="apiAuditModel.service_Details"
                    />
                  </div>

                  <div class="form-group" style="margin-top: 1.6%">
                    <button
                      type="button"
                      class="btn btn-primary btn-lg ladda-button ladda-button-demo"
                      data-style="zoom-in"
                      :disabled="invalid"
                      @click="submitData"
                    >
                      <i class="fa fa-search"></i>&nbsp;&nbsp; Search
                    </button>&nbsp;&nbsp;
                    <button
                      type="button"
                      class="btn btn-primary btn-lg ladda-button ladda-button-demo"
                      data-style="zoom-in"
                      :disabled="invalid"
                      @click="downloadData"
                    >Download</button>
                  </div>
                </div>
              </form>
            </ValidationObserver>

            <div class="hr-line-dashed"></div>

            <div class="table-responsive">
              <table
                :class="auditTableClass"
                class="table table-striped table-bordered table-hover"
              >
                <thead>
                  <tr>
                    <th>{{ apiAuditColumn.id }}</th>
                    <th>{{ apiAuditColumn.name }}</th>
                    <th>{{ apiAuditColumn.userId }}</th>
                    <th>{{ apiAuditColumn.serverDetails }}</th>
                    <th>{{ apiAuditColumn.requestDate }}</th>
                    <th>{{ apiAuditColumn.resultCode }}</th>
                    <th>{{ apiAuditColumn.action }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, key) in apiAuditData" :key="key">
                    <td>{{ value.id }}</td>
                    <td>{{ value.customerName == "" ? "NA" : value.customerName }}</td>
                    <td>{{ value.userId == "" ? "NA" : value.userId }}</td>
                    <td>{{ value.service_Details }}</td>
                    <td>
                      <i class="fa fa-clock-o" aria-hidden="true"></i>
                      &nbsp;&nbsp;
                      {{ value.callTime }}
                    </td>
                    <td>{{ value.resultCode }}</td>
                    <td>
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#viewModal"
                        @click="addModalData(value)"
                      >
                        View &nbsp;&nbsp;
                        <i class="fa fa-eye" aria-hidden="true"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ apiAuditColumn.id }}</th>
                    <th>{{ apiAuditColumn.name }}</th>
                    <th>{{ apiAuditColumn.userId }}</th>
                    <th>{{ apiAuditColumn.serverDetails }}</th>
                    <th>{{ apiAuditColumn.requestDate }}</th>
                    <th>{{ apiAuditColumn.resultCode }}</th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <AuditModal :data="data"></AuditModal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTableService from "../../framework/plugin/DataTableService";
import AxiosService from "../../framework/plugin/AxiosService";
import Constant from "../../framework/plugin/Constant";
import AuditModal from "../../framework/components/common/AuditModal";
import SearchCriteria from "../../framework/plugin/SearchCriteria";
import Utils from "../../framework/plugin/Utils";

export default {
  data() {
    return {
      apiAuditColumn: {
        id: "Id",
        name: "Customer Name",
        userId: "User Id",
        serverDetails: "Server Details",
        requestDate: "Time Stamp",
        resultCode: "Result Code",
        action: "Action"
      },
      auditTableClass: "auditTableClass",
      auditTitle: "API Audit",
      customerData: {},
      apiAuditData: {},
      data: {},
      statusCode: Constant.statusCodes,
      apiAuditModel: {
        customerName: "",
        userId: "",
        service_Details: "",
        callTime: "",
        resultCode: ""
      },
      searchCriteriaData: [],
      datePicker: "datefilter"
    };
  },
  components: {
    AuditModal
  },
  mounted: function() {
    var self = this;
    Ladda.bind(".ladda-button", { timeout: 2000 });
    $("#statusCode").change(function() {
      self.apiAuditModel.resultCode = $(this).val();
    });
    $("#customer").change(function() {
      self.apiAuditModel.customerName = $(this).val();
    });
    $('input[name="' + this.datePicker + '"]').on(
      "apply.daterangepicker",
      function(ev, picker) {
        setTimeout(() => {
          self.assignDatePickerValue();
        }, 200);
      }
    );
    $('input[name="' + this.datePicker + '"]').on(
      "cancel.daterangepicker",
      function(ev, picker) {
        setTimeout(() => {
          self.assignDatePickerValue();
        }, 200);
      }
    );
    Utils.datePickerInitalizer(this.datePicker, 7);
    DataTableService.loadDataTableWithoutExport(
      this.auditTableClass,
      this.auditTitle
    );
  },
  created: function() {
    this.loadapiAuditData();
    this.generateSearchCriteriaData();
  },
  methods: {
    assignDatePickerValue: function() {
      console.log($("#" + this.datePicker).val());
      this.apiAuditModel.callTime = $("#" + this.datePicker).val();
    },
    loadapiAuditData: function() {
      const self = this;
      AxiosService.get(Constant.url.API_AUDIT_GET_API_REQUEST_URI).then(
        result => {
          self.apiAuditData = result;
        }
      );
      AxiosService.get(Constant.url.CUSTOMER_KEY_VALUE).then(result => {
        self.customerData = result;
      });
    },
    addModalData: function(value) {
      this.data = value;
    },
    generateSearchCriteriaData: function() {
      const self = this;
      Utils.clearData(this.searchCriteriaData);
      $.each(this.apiAuditModel, function(key, value) {
        if (!Utils.isEmptyStr(value)) {
          if ("callTime" == key) {
            var data = Utils.splitStr(value, ":", 2);
            self.searchCriteriaData.push(
              new SearchCriteria(
                key,
                Utils.trimStr(data[0]) + " 00:00:00",
                Constant.criteria.GREATER_THAN_EQUAL
              )
            );
            self.searchCriteriaData.push(
              new SearchCriteria(
                key,
                Utils.trimStr(data[1]) + " 23:59:59",
                Constant.criteria.LESS_THAN_EQUAL
              )
            );
          } else {
            self.searchCriteriaData.push(
              new SearchCriteria(key, value, Constant.criteria.EQUAL)
            );
          }
        }
      });
    },
    submitData: function() {
      this.generateSearchCriteriaData();
      if (Utils.isEmptyStr(this.searchCriteriaData)) {
        toastr.error("Select at least one search criteria", "Error");
      } else {
        const self = this;
        AxiosService.post(
          Constant.url.AUDIT_SEARCH_API_REQUEST_CRITERIA,
          this.searchCriteriaData
        ).then(result => {
          self.apiAuditData = result;
          DataTableService.reLoadDataTable(self.auditTableClass);
          DataTableService.loadDataTableWithoutExport(
            self.auditTableClass,
            self.auditTitle
          );
        });
      }
    },
    downloadData: function() {
      this.generateSearchCriteriaData();
      if (Utils.isEmptyStr(this.searchCriteriaData)) {
        toastr.error("Select at least one search criteria", "Error");
      } else {
        const self = this;
        AxiosService.post(
          Constant.url.AUDIT_DOWNLOAD_API_REQUEST_CRITERIA,
          this.searchCriteriaData
        ).then(result => {});
      }
    }
  }
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
</style>
