<template>
  <div
    class="modal inmodal"
    id="viewModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content animated fadeIn">
        <div class="modal-body" style="white-space: pre">
          <h5>Request</h5>
          <pre>
          {{ data.request }}
          </pre>
          <br />
          <h5>Response</h5>
          <pre>
          {{ data.response }}
          </pre>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal" id="closeBtn">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../../plugin/AxiosService.js";
import Constant from "../../plugin/Constant.js";
import EventBus from "../../plugin/event-bus";

export default {
  props: {
    data: Object,
  },
  mounted: function () {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
.modal-lg {
  max-width: 1100px !important;
}
</style>
